"use strict";

import * as THREE from "three";
import TWEEN from "@tweenjs/tween.js";

const TWOPI = Math.PI * 2.0;
const TRANSITION_MS = 500;

export function Cyl(el, options) {
  /**
   * ---------- Vars ----------
   */
  // Image data
  this.figureEls = null;
  this.allImageElsHaveDims = false;
  this.imageUrls = null;
  this.numUniqueTextures = null;

  // Three.js
  this.renderer = null;
  this.scene = null;
  this.camera = null;
  this.carouselGroup = null;
  // Array of promises that resolve into materials, length matches the number of figure els
  this.materials = null;
  this.textures = null;
  // lengths of these vars match the # of cylinder segments as they appear in the scene
  this.meshes = null;
  this.textureHeights = [];
  this.textureWidths = [];
  this.textureRatios = [];
  this.numSegments = null;
  this.duplicationFactor = 0;
  // Raycaster utils
  let rayIntersected = null;
  const raycaster = new THREE.Raycaster();
  const pointer = new THREE.Vector2();

  // Animation
  this.time = 0.0;
  this.rafId = 0;

  // Carousel state
  let lbOpen = false;
  this.currentIndex = 0;
  this.transitioning = false;

  /**
   * ---------- Methods ----------
   */
  this._extractImageData = function () {
    this.imageUrls = [];
    this.captions = [];
    this.figureEls = Array.from(el.querySelectorAll("figure"));
    this.numUniqueTextures = this.figureEls.length;

    // grab urls and captions from DOM
    for (let i = 0; i < this.numUniqueTextures; i++) {
      const fig = this.figureEls[i];
      const imgEl = fig.querySelector("img");
      const captionEl = fig.querySelector("figcaption");

      if (imgEl.dataset.src) {
        this.imageUrls.push(imgEl.dataset.src);
      } else {
        this.imageUrls.push(imgEl.src);
      }
      this.captions.push(captionEl.innerText);
    }

    // check if each image has data attributes for height and width
    this.allImageElsHaveDims = this.figureEls.every((figureEl) => {
      const imgEl = figureEl.querySelector("img");
      return imgEl.dataset.width && imgEl.dataset.height;
    });

    // we're storing texture dimensions before the texture has loaded here,
    // we'll use these to create the geometries before the textures have loaded
    if (this.allImageElsHaveDims) {
      for (let i = 0; i < this.numUniqueTextures; i++) {
        const imgEl = this.figureEls[i].querySelector("img");
        const height = parseInt(imgEl.dataset.height);
        const width = parseInt(imgEl.dataset.width);
        this.textureHeights.push(height);
        this.textureWidths.push(width);
        this.textureRatios.push(height / width);
      }
    }

    // calc the minimum number of times the set of images needs to be duplicated to be at least >20
    if (this.imageUrls.length <= 15) {
      this.duplicationFactor = Math.ceil(25 / this.imageUrls.length);
    }

    if (this.duplicationFactor === 0) {
      this.numSegments = this.numUniqueTextures;
    } else {
      this.numSegments = this.numUniqueTextures * this.duplicationFactor;
    }
  };

  this._createMaterials = function () {
    this.textures = Array(this.numUniqueTextures).fill();
    this.materials = [];
    for (let i = 0; i < this.numUniqueTextures; i++) {
      const material = new THREE.MeshBasicMaterial({
        wireframe: !!options.debug,
        side: options.debug ? THREE.DoubleSide : false,
        // color: Math.random() * 0xffffff,
        opacity: 0,
        transparent: true,
        map: null,
      });

      this.materials.push(material);
    }
  };

  this._loadAllTextures = function () {
    this.textureWidths = Array(this.numUniqueTextures);
    this.textureHeights = Array(this.numUniqueTextures);
    this.textureRatios = Array(this.numUniqueTextures);

    for (let i = 0; i < this.numUniqueTextures; i++) {
      // populate textures array with promises
      this.textures[i] = new Promise((resolve) => {
        new THREE.TextureLoader().load(this.imageUrls[i], (texture) => {
          this.textures[i] = texture;

          // store metadata
          const image = texture.image;
          this.textureWidths[i] = image.width;
          this.textureHeights[i] = image.height;
          this.textureRatios[i] = image.height / image.width;

          // recompile material
          this.materials[i].map = texture;
          this.materials[i].needsUpdate = true;

          resolve(texture);
        });
      });
    }

    return Promise.all(this.textures);
  };

  this._createScene = function () {
    // scene
    this.scene = new THREE.Scene();

    // renderer
    const renderSize = new THREE.Vector2(el.offsetWidth, el.offsetHeight);
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(renderSize.x, renderSize.y);
    this.renderer.setClearColor(
      options.backgroundColor,
      options.backgroundOpacity
    );
    el.appendChild(this.renderer.domElement);

    // camera
    this.camera = new THREE.PerspectiveCamera(
      70,
      renderSize.x / renderSize.y,
      0.01,
      1
    );

    this.camera.position.z = 2;
  };

  this._createGeometries = function () {
    // object vars
    this.meshes = Array(this.numSegments);
    this.carouselGroup = new THREE.Group();

    // length of these arrays is equal to numSegments
    let dupedTextureWidths = Array(this.duplicationFactor)
      .fill([...this.textureWidths])
      .flat(); // length is equal to numSegments
    let dupedTextureRatios = Array(this.duplicationFactor)
      .fill([...this.textureRatios])
      .flat();
    let textureMidpoints = [];
    let thetas = [];

    let totalWidth = 0;
    const gutterPct = 0.15 / 100; // percentage of TWOPI
    const gutterRadians = gutterPct * TWOPI;

    // calculate total width
    for (let i = 0; i < this.numSegments; i++) {
      totalWidth += dupedTextureWidths[i];
    }

    // calculate and store theta length for each image
    for (let i = 0; i < this.numSegments; i++) {
      const textureWidth = dupedTextureWidths[i];
      const thetaLength = (textureWidth / totalWidth) * TWOPI;
      thetas.push(thetaLength);

      // store texuture midpoints
      let accumulatedWidth = 0;

      accumulatedWidth = accumulateToIndex(this.textureWidths, i);

      const midpoint = textureWidth / 2 + accumulatedWidth;
      const midpointRadians =
        (midpoint / totalWidth) * TWOPI - gutterRadians / 2;
      textureMidpoints.push(midpointRadians);
    }

    // create and rotate cylinder segments using calculated thetas
    for (let i = 0; i < this.numSegments; i++) {
      const relativeHeight = thetas[i] * dupedTextureRatios[i]; // cylinder height is 1 so derive height from theta using unitless ratio

      const geo = new THREE.CylinderGeometry(
        1,
        1,
        relativeHeight,
        32,
        1,
        true,
        0,
        thetas[i] - gutterRadians
      );

      // create mesh
      const mesh = new THREE.Mesh(
        geo,
        this.materials[i % this.numUniqueTextures]
      );
      mesh.DOMElement = this.figureEls[i]; // bind the DOM el and mesh together
      mesh.index = i;
      this.meshes[i] = mesh;

      // rotate
      mesh.rotation.y = accumulateToIndex(thetas, i);

      this.carouselGroup.add(mesh);
    }

    this.carouselGroup.rotation.y -= textureMidpoints[0];
    this.scene.add(this.carouselGroup);

    fitCameraToCenteredObject(this.camera, this.carouselGroup, 1, this.scene);
  };

  this._draw = function () {
    this.time += 0.01;
    this.rafId = requestAnimationFrame(this._draw.bind(this));

    if (this.transitioning) {
      TWEEN.update();
      this.renderer.render(this.scene, this.camera);
    }

    // object picking
    raycaster.setFromCamera(pointer, this.camera);
    const intersects = raycaster.intersectObjects(
      this.carouselGroup.children,
      false
    );

    if (intersects.length > 0) {
      rayIntersected = intersects[0].object;

      if (!lbOpen) {
        el.style.cursor = "pointer";
      }
    } else {
      rayIntersected = null;
      el.style.cursor = "";
    }
  };

  this._bindEvents = function () {
    window.addEventListener("resize", () => {
      const renderSize = new THREE.Vector2(el.offsetWidth, el.offsetHeight);
      this.renderer.setSize(renderSize.x, renderSize.y);
      this.camera.aspect = el.offsetWidth / el.offsetHeight;
      this.camera.updateProjectionMatrix();
      fitCameraToCenteredObject(this.camera, this.carouselGroup, 1, this.scene);
      this.renderer.render(this.scene, this.camera);
    });

    // Lightbox Event Listeners
    el.addEventListener("mousemove", this._onPointerMove);
    el.addEventListener("click", (e) => {
      this._openLightbox(e);
    });

    if (el.querySelector(".lightbox")) {
      el.querySelector(".close-lightbox").addEventListener(
        "click",
        this._closeLightbox
      );
      el.querySelector(".lightbox .controls.left").addEventListener(
        "click",
        () => {
          this._lightboxPrev();
        }
      );
      el.querySelector(".lightbox .controls.right").addEventListener(
        "click",
        () => {
          this._lightboxNext();
        }
      );
    }
  };

  this._setDebug = function () {
    if (options.debug) {
      // outline canvas
      el.style = "outline: 1px solid blue";

      // centered dot
      const dotGeometry = new THREE.BufferGeometry();
      dotGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(new THREE.Vector3().toArray(), 3)
      );
      const dotMaterial = new THREE.PointsMaterial({
        size: 0.1,
        color: 0xff0000,
      });
      const dot = new THREE.Points(dotGeometry, dotMaterial);
      this.scene.add(dot);

      // outline bounding box
      const box = new THREE.BoxHelper(this.carouselGroup, 0xff0000);
      this.scene.add(box);
    }
  };

  this._loadAdjacentTextures = function () {
    const delta = 3;

    for (let i = delta * -1; i <= delta; i++) {
      const targetIndex = this.currentIndex + i; // currentIndex may change so save the calc'd value here
      const currTexture = getWrappedArrayEl(this.textures, targetIndex);

      if (!currTexture) {
        const promise = new Promise((resolve) => {
          const url = getWrappedArrayEl(this.imageUrls, targetIndex);

          new THREE.TextureLoader().load(
            url,
            ((texture) => {
              setWrappedArrayEl(this.textures, targetIndex, texture);

              const currentMesh = getWrappedArrayEl(this.meshes, targetIndex);

              currentMesh.material.map = texture;
              currentMesh.material.opacity = 1;
              currentMesh.material.needsUpdate = true;

              this.renderer.render(this.scene, this.camera);

              resolve(texture);
            }).bind(this) // bind this for correct scope
          );
        });

        setWrappedArrayEl(this.textures, targetIndex, promise);
      }
    }
  };

  this.next = function () {
    this.handleClick("right");
  };

  this.prev = function () {
    this.handleClick("left");
  };

  this.handleClick = function (dir) {
    if (this.transitioning) return;

    let dupedTextureWidths = Array(this.duplicationFactor)
      .fill([...this.textureWidths])
      .flat();
    const prevIndex = this.currentIndex;

    this._hideCaption();

    if (dir === "left") {
      this.currentIndex =
        this.currentIndex === 0 ? this.numSegments - 1 : this.currentIndex - 1;
    } else {
      this.currentIndex =
        this.currentIndex === this.numSegments - 1 ? 0 : this.currentIndex + 1;
    }

    // calculate angle difference
    let oldPos = 0;
    let newPos = 0;

    oldPos = accumulateToIndex(dupedTextureWidths, prevIndex);
    oldPos += dupedTextureWidths[prevIndex] / 2;

    newPos = accumulateToIndex(dupedTextureWidths, this.currentIndex);
    newPos += dupedTextureWidths[this.currentIndex] / 2;

    const delta = newPos - oldPos;
    let deltaRadians =
      (delta / dupedTextureWidths.reduce((a, b) => a + b)) * TWOPI;

    // handle cycles
    if (this.currentIndex === this.numSegments - 1 && prevIndex === 0) {
      deltaRadians = deltaRadians - TWOPI;
    } else if (this.currentIndex === 0 && prevIndex === this.numSegments - 1) {
      deltaRadians = deltaRadians + TWOPI;
    }

    // lazy load edge images
    if (this.allImageElsHaveDims) {
      this._loadAdjacentTextures();
    }

    // rotate
    this.transitioning = true;

    this.tween = new TWEEN.Tween(this.carouselGroup.rotation)
      .to(
        {
          x: 0,
          y: this.carouselGroup.rotation.y - deltaRadians,
          z: 0,
        },
        TRANSITION_MS
      )
      .easing(TWEEN.Easing.Quadratic.InOut);

    this.tween.start();

    setTimeout(() => {
      this.transitioning = false;
      this._setCaption();
    }, TRANSITION_MS);
  };

  this._hideCaption = function () {
    let cycledIndex = null;

    if (!this.figureEls[this.currentIndex]) {
      // handle case where images are duped to fill space
      cycledIndex = this.currentIndex % this.numUniqueTextures;
    }

    // hide old caption
    if (!this.figureEls[this.currentIndex]) {
      this.figureEls[cycledIndex].classList.remove("active");
    } else {
      this.figureEls[this.currentIndex].classList.remove("active");
    }
  };

  this._setCaption = function () {
    let cycledIndex = null;

    if (!this.figureEls[this.currentIndex]) {
      // handle case where images are duped to fill space
      cycledIndex = this.currentIndex % this.numUniqueTextures;
    }

    if (!this.figureEls[this.currentIndex]) {
      this.figureEls[cycledIndex].classList.add("active");
    } else {
      this.figureEls[this.currentIndex].classList.add("active");
    }
  };

  this._onPointerMove = function (event) {
    pointer.x = (event.clientX / el.offsetWidth) * 2 - 1;
    pointer.y = -(event.clientY / el.offsetHeight) * 2 + 1;
  };

  this._openLightbox = function (event) {
    const intersectedIsCentered =
      rayIntersected && rayIntersected.index === this.currentIndex;

    // allow only the middle image to be opened
    if (!rayIntersected || !intersectedIsCentered) return;

    if (rayIntersected && !lbOpen) {
      // clone figureEls into .lightbox
      const lightboxEl = el.querySelector(".lightbox");
      lightboxEl.classList.add("lightbox--active");
      const cloneTarget = lightboxEl.querySelector(".clones");

      this.figureEls.forEach((el) => {
        // set src and srcset if they exist as data attributes
        const imgEl = el.querySelector("img");
        if (imgEl.dataset.src) {
          imgEl.src = imgEl.dataset.src;
        }
        if (imgEl.dataset.srcset) {
          imgEl.srcset = imgEl.dataset.srcset;
        }

        cloneTarget.appendChild(el.cloneNode(true));
      });

      document.body.style.overflow = "hidden";
      el.style.cursor = "";

      lbOpen = true;
    }

    document.addEventListener("keyup", (e) => {
      this._onEscKeyUp(e);
    });
  };

  this._closeLightbox = function (event) {
    const lightboxEl = el.querySelector(".lightbox");
    lightboxEl.classList.remove("lightbox--active");

    const clonedFigureEls = Array.from(lightboxEl.querySelectorAll("figure"));
    clonedFigureEls.forEach((el) => {
      el.remove();
    });

    document.body.style.overflow = "";
    document.removeEventListener("keyup", this._onEscKeyUp);

    lbOpen = false;
  };

  this._onEscKeyUp = function (event) {
    if (event.key === "Escape" || event.keyCode === 27) {
      this._closeLightbox();
      return;
    }
  };

  this._lightboxNext = function (event) {
    const activeEl = el.querySelector(".clones figure.active");
    const nextSibling = activeEl.nextElementSibling;
    let targetEl = null;

    if (nextSibling) {
      targetEl = nextSibling;
    } else {
      targetEl = el.querySelector(".clones figure"); // cycle to first child
    }

    targetEl.classList.add("active");
    activeEl.classList.remove("active");
  };

  this._lightboxPrev = function (event) {
    const activeEl = el.querySelector(".clones figure.active");
    const prevSibling = activeEl.previousElementSibling;
    let targetEl = null;

    if (prevSibling) {
      targetEl = prevSibling;
    } else {
      const clones = Array.from(el.querySelectorAll(".clones figure")); // cycle to last child
      targetEl = clones[clones.length - 1];
    }

    targetEl.classList.add("active");
    activeEl.classList.remove("active");
  };

  this._init = async function () {
    // setup
    this._extractImageData();
    this._createMaterials();

    if (!this.allImageElsHaveDims) {
      await this._loadAllTextures(); // eager
    } else {
      this._loadAdjacentTextures(); // lazy
    }

    // scene
    this._createScene();
    this._createGeometries();

    // DOM things
    this._bindEvents();
    this._setCaption();
    this._setDebug();

    // initial render
    this.renderer.render(this.scene, this.camera);

    // recursive draw loop
    this._draw();
  };

  this._init();
}

/**
 * ---------- Utils ----------
 */
function accumulateToIndex(arr, index) {
  let result = 0;

  for (let i = 0; i < index; i++) {
    result += arr[i];
  }

  return result;
}

function getWrappedArrayEl(arr, i) {
  // if the index is negative, calculate the corresponding positive index
  // relative to the end of the array
  if (i < 0) {
    i = arr.length + i;
  }

  // make sure i <= arr.length
  i = i % arr.length;

  return arr[i];
}

function setWrappedArrayEl(arr, i, val) {
  // if the index is negative, calculate the corresponding positive index
  // relative to the end of the array
  if (i < 0) {
    i = arr.length + i;
  }

  // make sure i <= arr.length
  i = i % arr.length;

  arr[i] = val;
}

function rads(deg) {
  return deg * (Math.PI / 180);
}

function deg(rads) {
  return rads * (180 / Math.PI);
}

function isPortrait() {
  if (window.innerHeight / window.innerWidth > 1) {
    return true;
  } else {
    return false;
  }
}

// Modified from:
// https://wejn.org/2020/12/cracking-the-threejs-object-fitting-nut/
const fitCameraToCenteredObject = function (camera, object, offset = 0, scene) {
  const boundingBox = new THREE.Box3();
  // kaje edit
  boundingBox.setFromObject(object, true); // 2nd param increases bounding box accuracy

  const middle = new THREE.Vector3();
  const size = new THREE.Vector3();
  boundingBox.getSize(size);

  // figure out how to fit the box in the view:
  // 1. figure out horizontal FOV (on non-1.0 aspects)
  // 2. figure out distance from the object in X and Y planes
  // 3. select the max distance (to fit both sides in)
  //
  // The reason is as follows:
  //
  // Imagine a bounding box (BB) is centered at (0,0,0).
  // Camera has vertical FOV (camera.fov) and horizontal FOV
  // (camera.fov scaled by aspect, see fovh below)
  //
  // Therefore if you want to put the entire object into the field of view,
  // you have to compute the distance as: z/2 (half of Z size of the BB
  // protruding towards us) plus for both X and Y size of BB you have to
  // figure out the distance created by the appropriate FOV.
  //
  // The FOV is always a triangle:
  //
  //  (size/2)
  // +--------+
  // |       /
  // |      /
  // |     /
  // | F° /
  // |   /
  // |  /
  // | /
  // |/
  //
  // F° is half of respective FOV, so to compute the distance (the length
  // of the straight line) one has to: `size/2 / Math.tan(F)`.
  //
  // FTR, from https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
  // the camera.fov is the vertical FOV.

  const fov = camera.fov * (Math.PI / 180);
  const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
  const dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2));
  const dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));

  // kaje edit
  // let cameraZ = Math.max(dx, dy);
  let cameraZ;
  if (size.x > size.y) {
    cameraZ = dy;
  } else {
    cameraZ = dx;
  }

  // offset the camera, if desired (to avoid filling the whole canvas)
  if (offset !== undefined && offset !== 0) cameraZ *= offset;

  // kaje edit
  // don't clobber debug settings
  camera.position.set(camera.position.x, camera.position.y, cameraZ);
  camera.lookAt(0, 0, 0);

  // kaje edit
  // don't need to reset far plane bc radius = 1
  // set the far plane of the camera so that it easily encompasses the whole object
  // const minZ = boundingBox.min.z;
  // const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

  // camera.far = cameraToFarEdge * 3;
  camera.updateProjectionMatrix();
};
