import barba from '@barba/core'
import Flickity from 'flickity'
import Masonry from 'masonry-layout'
import ImagesLoaded from 'imagesloaded'
import Pageable from 'pageable'
import Fullpage from 'fullpage.js'
import LazyLoad from "vanilla-lazyload"
import { Cyl } from './cyl.js'

window.addEventListener('load', (event) => {
  initMasonry()
})

// menu()
initSlider()
initShopSliders()
initCyls()
initFullpage()
openLightbox()
initLazyload()
// filterButtons()
// filterClicks()

function initLazyload(){
  let lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  })
}

function openLightbox(){
  let grids = document.querySelectorAll('.image-grid')
  for (let i = 0; i < grids.length; ++i) {
    let figs = grids[i].querySelectorAll('.image-grid figure')
    for (let i = 0; i < figs.length; ++i) {
      figs[i].querySelector('img').addEventListener('click', (e) => {
        let lightbox = figs[i].parentElement.querySelector('.lightbox')
        lightbox.classList.add('lightbox--active')
        let lightboxLazy = new LazyLoad({
          container: lightbox
        })
        lightboxLazy.loadAll()
        let clones = lightbox.querySelector('.clones')
        let cloneFigs = clones.querySelectorAll('figure')
        for (let a = 0; a < cloneFigs.length; ++a){
          cloneFigs[i].classList.add('active')
        }

        document.body.style.overflow = "hidden";

        lightbox.querySelector(".controls.right").addEventListener(
          "click",
          () => {
            let activeEl = lightbox.querySelector(".clones figure.active")
            let nextSibling = activeEl.nextElementSibling
            let targetEl = null;

            if (nextSibling) {
              targetEl = nextSibling
            } else {
              targetEl = lightbox.querySelector(".clones figure"); // cycle to first child
            }

            targetEl.classList.add("active")
            activeEl.classList.remove("active")
          }
        );
        lightbox.querySelector(".controls.left").addEventListener(
          "click",
          () => {
            let activeEl = lightbox.querySelector(".clones figure.active")
            let prevSibling = activeEl.previousElementSibling
            let targetEl = null

            if (prevSibling) {
              targetEl = prevSibling
            } else {
              let clones = Array.from(lightbox.querySelectorAll(".clones figure")) // cycle to last child
              targetEl = clones[clones.length - 1]
            }

            targetEl.classList.add("active")
            activeEl.classList.remove("active")
          }
        );
        let close = lightbox.querySelector('.close-lightbox')
          close.addEventListener('click', (e) => {
            let activeEl = lightbox.querySelector(".clones figure.active")

            lightbox.classList.remove('lightbox--active')
            activeEl.classList.remove("active")
            document.body.style.overflow = "";

          })
        // imageGrids[i].querySelector('.lightbox').classList.add('active')
      })
    }
  }
}

function initFullpage(){
  let fullpageEl = document.querySelector('#event-pages')

  if(fullpageEl && fullpageEl.childElementCount > 1){
    const fullpage = new Fullpage( fullpageEl, {
      licenseKey: 'D94K9-EP8ZI-5KG47-1JZK7-YERMN'
    })
    barba.hooks.after((data) => {
      let nextPath = data.next.url.path
      if (nextPath !== '/upcoming' || nextPath !== '/current'){
        fullpage_api.destroy()
      }
    })
  }
}

function destroyFullpage(){
  let fullpageEl = document.querySelector('.fullpage-wrapper')
  if(fullpageEl) {
      fullpage_api.destroy('all')
  }
}

function initSlider(){
  let slideshow = document.querySelector('#slideshow')
  if(slideshow){
    let flkty = new Flickity( slideshow, {
      cellAlign: 'center',
      contain: true,
      pageDots: true,
      prevNextButtons: false,
      freeScroll: false,
      autoPlay: 3000,
      pauseAutoPlayOnHover: false,
      wrapAround: true,
      accessibility: true
    })
  }
}

function initShopSliders(){
  let shopSliders = document.querySelectorAll('.cell-slider')
  if(shopSliders){
    for ( var i=0, len = shopSliders.length; i < len; i++ ) {
      let slider = shopSliders[i]
      new Flickity( slider, {
        cellAlign: 'center',
        contain: true,
        pageDots: true,
        prevNextButtons: false,
        freeScroll: false,
        autoPlay: false,
        pauseAutoPlayOnHover: false,
        wrapAround: true,
        accessibility: true
      })
    }
  }
}

function initMasonry(){
  let grid = document.querySelector('#home-grid')
  if(grid){
    let msnry = new Masonry( grid, {
      itemSelector: '.home-cell',
      columnWidth: 1
    })
    ImagesLoaded( grid, function(){
      let homeImages = document.querySelectorAll('#home-grid figure'), i
      for (i = 0; i < homeImages.length; ++i) {
        homeImages[i].classList.add('loaded')
      }
      let homeTexts = document.querySelectorAll('#home-grid .text-cell'), ii
      for (ii = 0; ii < homeTexts.length; ++ii) {
        homeTexts[ii].classList.add('loaded')
      }
      msnry.layout()
      grid.classList.add('loaded')
    })
  }
}

barba.init({
  schema:{
    prefix: 'data-pjax'
  },
  transitions: [{
    name: 'default-transition',
    sync: true,
    leave(data){
    },
    beforeEnter(data){
    },
    enter(data){
      const body = document.body
      const container = data.next.container

      let menuItemList = document.querySelectorAll('.menu-item'), i
      let nextPath = data.next.url.path
      let section = container.dataset.section

      body.className = ''
      body.classList.add(section)

      container.classList.add('loading')

      setTimeout(() => {
        container.classList.remove('loading')
        initFullpage()
      }, 200)

      window.scrollTo({ top: 0 })
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual'
      }

      for (i = 0; i < menuItemList.length; ++i) {
        if(menuItemList[i].getAttribute('href') == nextPath){
          menuItemList[i].classList.add('active')
        }
        else{
          menuItemList[i].classList.remove('active')
        }
      }
      destroyFullpage()
      initCyls()
      initSlider()
      initShopSliders()
      initMasonry()
      openLightbox()
      initLazyload()
    },
  }]
})

function initCyls() {
  const cylEls = Array.from(document.querySelectorAll('.cyl'))

  cylEls.forEach((el) => {
    const cyl = new Cyl(el, {
      backgroundColor: 0x000000,
      backgroundOpacity: 0.0,
      // debug: true
      debug: false
    })

    el.querySelector('.controls.left').addEventListener('click', (e) => {
      cyl.prev()
      e.stopPropagation()
    })

    el.querySelector('.controls.right').addEventListener('click', (e) => {
      cyl.next()
      e.stopPropagation()
    })

    el.addEventListener('swiped-left', function(e) {
      cyl.next()
    })

    el.addEventListener('swiped-right', function(e) {
      cyl.prev()
    })

    console.log(cyl)
  })
}

function filterClicks() {
  const filters = document.querySelectorAll('.filter.button')

  if( filters.length ) {
    for (i = 0; i < filters.length; ++i) {
      filters[i].addEventListener('click', function(event) {
        event.preventDefault()
        filterButtons()
      })
    }
  }
}
